.screen-header {
  position: relative;
  top: 0;
  left: 0;
  height: 3.75rem;
  width: 100%;
}

.screen-header-container {
  display: flex;
  width: 100vw;
  padding-top: 1.15rem;
  justify-content: center;
  align-items: center;
  padding-bottom: 1.15rem;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 97.36%,
    #c4c4c4 0%
  );
}

.logo {
  width: 60%;
  height: 1.75rem;
}

@media (min-width: 600px) {
  .screen-header {
    height: 5.6rem;
  }

  .screen-header-container {
    padding-left: 5.6rem;
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
    justify-content: unset;
  }

  .logo {
    width: 26rem;
    height: 2.8rem;
  }
}
