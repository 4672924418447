.main-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: linear-gradient(180deg, #08294C 0%, #175BA2 100%);
}

.container {
  background-image: url("../assets/images/mobile-BG.webp");
  background-repeat:initial;
  background-size:contain;
  display: flex;
  flex-direction: column;
  padding-top: 2%;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.title-text {
  font-family: "Inter";
  font-weight: 500;
  font-style: normal;
  font-size: 1rem;
  line-height: 1.85rem;
  text-align: center;
}

.subTitle-text {
  font-family: "Inter";
  font-weight: 700;
  font-style: normal;
  font-size: 1.85rem;
  line-height: 2.25rem;
  text-align: center;
}

.player-image {
  margin-top: 0.5rem;
  height: 320px;
  width: 100%;
  object-fit: contain;
}

.desktop-players {
  display: none;
}

.mobile-players {
  display: flex;
  width: 85vw;
  height: 25rem;
  /* padding-top: 12px; */
  /* margin-bottom: 20px; */
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-left: 3.8rem;
  padding-right: 3.8rem;
  border-radius: 0.625rem;
  width: fit-content;
  text-decoration: none;
  background-image: linear-gradient(180deg, #00A79D 0%, #07AA53 100%);
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.3);
}

.desktop-button-container {
  display: none;
}

.mobile-download-button {
  height: 2rem;
  width: 2rem;
  margin-right: 1rem;
}

.mobile-download-text {
  font-family: "Inter";
  font-weight: 700;
  font-style: normal;
  font-size: 1rem;
  line-height: 50px;
  letter-spacing: 20%;
  color: #ffffff;
  text-transform: uppercase;
}

.desktop-download-button {
  display: none;
}

.sub-container {
  display: flex;
  background-color: #0B1124;
  flex-direction: column;
  padding-top: 2rem;
}

.link-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #0B1124;
}

.link-text {
  font-family: "Inter";
  font-weight: 300;
  font-style: normal;
  font-size: 1.1rem;
  line-height: 1.5rem;
  color: #A8D3FF;
  text-align: center;
  margin-bottom: 2.5rem;
  text-decoration: none;
}

.link {
  text-decoration: none;
}

.copyright-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #464646;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.copyright-text {
  font-family: "Inter";
  font-weight: 400;
  font-style: regular;
  font-size: 0.625rem;
  line-height: 1.5rem;
  color: #ffffff;
  text-align: center;
}

.copyright-owner-text {
  font-family: "Inter";
  font-weight: 700;
  font-style: regular;
  font-size: 0.625rem;
  line-height: 1.5rem;
  color: #ffffff;
  text-align: center;
}

.mobile-separator {
  border: 0.5px solid #ffffff;
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  height: 50%;
}

.tagline {
  margin-top: 1.8rem;
  width: 100%;
  height: 8rem;
  margin-left: 1.2rem;
}

.desktop-tagline {
  display: none;
}

.desktop-gameTitle {
  margin-top: 2rem;
  width: 90%;
  height: 5rem;
  margin-left: 1rem;
}

.button-tagline {
  color: #ffffff;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 3rem;
  text-align: center;
}

.copyright,
.copyright-lp1 {
  align-items: center;
  color: white;
  display: flex;
  flex-direction: row;
  font-size: 0.6rem;
  justify-content: center;
  margin-bottom: 59px;
  text-align: center;
}

.blur-download-button-container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 7.5rem;
  bottom: -0.1rem;
  z-index: 1;
  background-color: black;
  /* backdrop-filter: blur(8px); */
}

.download-button-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 1rem;
  bottom: -0.1rem;
  z-index: 1;
}

.games-section {
  text-align: center;
  padding: 40px 20px;
  background-color: #0B1124;
}

.games-section h2 {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 10px;
  background-color: #ffffff
}

.games-section .highlight {
  color: #FFCA43;
  font-weight: bold;
}

.games-section p {
  font-size: 1rem;
  color: #555;
  background-color: #ffffff
}

.games-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns on smaller screens */
  justify-content: center;
  padding: 0 20px;
}

.game-item {
  text-align: center;
  transition: transform 0.3s ease;
}

.games-grid img {
  width: 80%;
}

.game-item p {
  font-weight: bold;
  font-size: 0.9rem;
  background-color: #0B1124;
  color: #ffffff;
}

.game-item:hover {
  transform: translateY(-5px);
}

.winDaily {
  width: 80%;
  margin-bottom: 10px;
}

.byPlaying {
  width: 60%;
  margin-bottom: 10px;
}

.bottom-mobile-text {
  display: flex;
  font-weight: 400;
  color: white;
  padding-bottom: 10px;
}



@media (min-width: 800px) {

  .blur-download-button-container {
    display: none;
  }

  .download-button-container {
    display: none;
  }

  .container {
    background-image: url("../assets/images/desktop-BG.webp");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    /* justify-content: space-evenly; */
    flex-direction: row;
    align-items: flex-end;
  }

  .title-text {
    font-size: 2.813rem;
    line-height: 2.813rem;
    text-align: left;
  }

  .subTitle-text {
    font-size: 65px;
    line-height: 74px;
    text-align: left;
  }

  .player-image {
    margin-top: 40px;
    height: 50rem;
    width: 40%;
    flex: 1;
    object-fit: fill;
  }

  .desktop-players {
    display: flex;
    width: 60%;
    height: 100%;
    /* object-fit: contain; */
  }

  .mobile-players {
    display: none;
  }

  .text-container {
    padding-left: 5.6rem;
    flex: 1;
  }

  .button-container {
    display: none;
  }

  .desktop-button-container {
    display: flex;
    align-items: center;
    margin-top: 1.25rem;
    text-decoration: none;
    width: 100%;
  }

  .desktop-download-button {
    display: flex;
    height: 26px;
    width: 26px;
    margin-right: 0.625rem;
  }

  .download-text {
    font-family: "Inter";
    font-weight: 300;
    font-style: normal;
    font-size: 1.5rem;
    line-height: 2rem;
    color: #666666;
  }

  .sub-container {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    background-color: #051D39;
    justify-content: space-between;
    flex-direction: row;
  }

  .link-container {
    display: flex;
    flex-direction: row;
    padding-left: 5.6rem;
    background: unset;
    justify-content: space-between;
    flex: 0.5;
  }

  .link-text {
    font-family: "Inter";
    font-weight: 300;
    font-style: normal;
    font-size: 1.25rem;
    line-height: 1.5rem;
    text-align: left;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .separator {
    border: 0.5px solid #ffffff;
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    height: 70%;
  }

  .copyright-container {
    justify-content: unset;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-right: 3.75rem;
  }

  .copyright-text {
    font-family: "Inter";
    font-weight: 400;
    font-style: regular;
    font-size: 0.625rem;
    line-height: 1.5rem;
    color: #ffffff;
    text-align: center;
  }
  .copyright-owner-text {
    font-family: "Inter";
    font-weight: 700;
    font-style: regular;
    font-size: 0.625rem;
    line-height: 1.5rem;
    color: #ffffff;
    text-align: center;
  }
  .mobile-separator {
    border: 0.5px solid #ffffff;
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    height: 50%;
  }
  .tagline {
    display: none;
  }
  
  .desktop-tagline {
    margin-top: 6rem;
    display: flex;
    width: 30rem;
    height: 20rem;
    margin-left: 2rem;
  }

  .desktop-gameTitle {
    margin-top: 80px;
    display: flex;
    width: 40rem;
    height: 10rem;
  }

  .copyright {
    color: rgba(255, 255, 255, 1);
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1rem;
    margin-bottom: 0px;
    margin-right: 7rem;
  }

  .button-tagline {
    display: none;
  }
}
